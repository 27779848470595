/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import * as d3 from "d3";

export default class {
    init = true;
    xaxis = null;
    yaxis = null;
    tooltip = null;
    tooltiptext = null;
    column;
    id;
    svg = null;
    width = 500;
    height = 300;
    date_column = "DATE";
    popupContent = function (element) {
        var options = {
            weekday: "long",
            month: "long",
            day: "numeric",
        };
        console.log(element);
        var day = new Intl.DateTimeFormat("nl-NL", options).format(
            new Date(element.__data__.DATE)
        );
        var html = "<h6>" + day + "</h6>";
        if (element.__data__.VALUE !== undefined) {
            html +=
                d3.format(",.0f")(element.__data__.VALUE) + " aanvragen";
        }
        if (element.__data__.TOTAL_IN_ICU !== undefined) {
            html +=
                '<tr><td class="value">' +
                d3.format(",.0f")(element.__data__.TOTAL_IN_ICU) +
                "</td><td>in ICU</td></tr>";
        }
        if (element.__data__.TOTAL_IN_RESP !== undefined) {
            html +=
                '<tr><td class="value">' +
                d3.format(",.0f")(element.__data__.TOTAL_IN_RESP) +
                "</td><td>on respirator</td></tr>";
        }

        html += "</table>";
        return html;
    };

    container;
    svgcontainer;
    margin = { top: 10, left: 10, bottom: 10, right: 80 };

    constructor(containerId, data) {
        window.addEventListener("resize", this.redraw.bind(this));



        this.tooltip = d3.select("#tooltip");
        this.tooltiptext = d3.select("#tooltiptext");

        this.data = data;
        this.containerId = containerId;
        this.container = d3.select("#" + this.containerId);
        this.width = Math.floor(this.container.node().getBoundingClientRect().width);
        this.height = Math.floor(this.container.node().getBoundingClientRect().height);

        this.svgcontainer = this.container
            .append("svg");

        this.svg = this.svgcontainer
            .attr("width", this.width)
            .attr("height", this.height)
            .append("g");
    }

    redraw() {
        this.width = Math.floor(this.container.node().getBoundingClientRect().width);
        this.height = Math.floor(this.container.node().getBoundingClientRect().height);
        this.svgcontainer.attr('width', this.width);
        this.svgcontainer.attr('height', this.height);
        // Move y axis
        this.yaxis
            .attr("transform", `translate(${this.width - this.margin.right},0)`)
            .attr("class", "y-axis axisHidden");

        this.x = d3
            .scaleTime()
            .domain(this.dateRange)
            .range([this.margin.left, this.width - this.margin.right]);

        var test = d3.axisBottom(this.x).ticks(Math.min(2, Math.floor(this.width / 200)));
        this.xaxis.attr(
            "transform",
            `translate(${this.margin.left}, ${this.height - this.margin.bottom - 10})`
        ).call(test);

        this.draw(this.column, this.id);
    }

    popup(method) {
        this.popupContent = method;
    }

    dateColumn(date_column) {
        this.date_column = date_column;
    }

    delayFunction(d, i) {
        return i * 8;
    }

    update(data) {
        this.data = data;
        this.convertDates();
    }

    convertDates() {
        let date_column = this.date_column;
        if (
            !(
                Object.prototype.toString.call(this.data[0][date_column]) ==
                "[object Date]"
            )
        ) {
            // Convert data into proper data
            this.data.forEach(function (d) {
                d[date_column] = d3.timeParse("%Y-%m-%d")(d[date_column]);
            });
        }
    }

    draw(column, id) {
        this.id = id;
        this.column = column;
        this.convertDates();
        var dateRange = [
            d3.min(this.data.map((d) => d[this.date_column])),
            d3.max(this.data.map((d) => d[this.date_column])),
        ];
        this.dateRange = dateRange;
        var width = this.width - this.margin.left - this.margin.right,
            height = this.height - this.margin.top - this.margin.bottom;

        const x = d3
            .scaleTime()
            .domain(
                d3.extent([
                    d3.min(this.data, (d) => d[this.date_column]),
                    d3.max(this.data, (d) => d[this.date_column]),
                ])
            )

            .rangeRound([this.margin.left, width]);

        const y = d3
            .scaleLinear()
            .domain([0, d3.max(this.data, (d) => +d[column])])
            .range([height - this.margin.bottom, this.margin.top]);

        //      const parseTime = d3.timeParse("%b %d %Y");

        var bars = this.svg.selectAll("rect").data(this.data);
        bars.exit().remove();
        let that = this;
        let barWidth = Math.floor((width / this.data.length) * 3 / 4);
        console.log('wb2', barWidth, width, this.data.length);
        bars
            .transition()
            .delay((d, i) => ((i * i) / (that.data.length * that.data.length)) * 500)
            //             .delay(function (d, i) {
            //                 return y(d[column]);//i * x(d[this.date_column]);//x(d[this.date_column]);
            //                 // if (d3.select(this).attr("x") !== x(d[this.date_column])) {
            //                 //     return i * 3;
            //                 // }
            // //                return i * 20;
            //             })
            .duration(400)
            .attr("height", (d) => y(0) - y(+d[column]))
            .attr("width", barWidth)

            .attr("y", (d) => y(d[column]))
            .attr("x", (d) => {
                return x(d[this.date_column]) - barWidth / 2;
            })
            .attr("fill", "url(#Gradient" + id + "1)");
        var containerId = this.containerId;
        bars
            .enter()
            .append("rect")
            .attr("fill", "url(#Gradient" + id + "1)")

            .on("mouseover", function (e) {
                d3.select(this).attr('class', 'hover');

                that.tooltip
                    .style(
                        "left",
                        parseInt(d3.select(this).attr("x")) +
                        parseInt(d3.select(this).attr("width")) / 2 +
                        parseInt(document.getElementById(containerId).offsetLeft) -
                        105 +
                        "px"
                    )
                    .style(
                        "top",

                        document.getElementById(containerId).getBoundingClientRect()
                            .bottom -
                        20 +
                        "px"
                    );
                that.tooltiptext.html(that.popupContent(e.target));
                that.tooltip.style("display", 'block');
            })
            .on("mouseout", function (e) {
                d3.select(this).attr('class', '');
                that.tooltip.style("display", "none");
            })
            .attr("rx", 3)
            .attr("x", (d) => x(d[this.date_column]))
            .attr("height", (d) => y(0) - y(d[column]))
            .attr("y", (d) => y(0))
            .attr("height", 0)
            .attr("width", barWidth)

            .transition()
            .delay(this.delayFunction)

            .duration(400)
            .attr("y", (d) => y(d[column]))

            .attr("height", (d) => y(0) - y(d[column]));

        // NEW STUFF HERE
        const len = this.data.length - 1;
        const tickValuesForAxis = this.data.map((d, i) => {
            //  if (i % parseInt(this.data.length / 5) == 0 || i == len || i == 0)
            return d[this.date_column];
            //
        });
        if (this.init) {
            // We do this so we can only show tick lines for the ticks
            // that we want to show the text labels for -- that itself
            // is done further down in the tickValues() call.

            this.xaxis = this.svg
                .append("g")
                .attr("class", "xaxis axisHidden")
                .attr(
                    "transform",
                    `translate(${this.margin.left}, ${height - this.margin.bottom + 10})`
                );
            // .call(
            //   d3
            //     .axisBottom(x)
            //     .tickValues(tickValuesForAxis)
            //               .ticks(4)
            //     //            .tickSize(10)
            //     .tickFormat((d, i) => {
            //       if (i % parseInt(this.data.length / 50) !== 0) return "";
            //       var r = "";
            //       var dt = parseTime(d);
            //       //if (i % parseInt(len/5) == 0 || i == len) r = formatTime(dt);
            //       return d;
            //     })
            // )
            //        var yAxis = d3.axisRight().scale();
            this.yaxis = this.svg
                .append("g")
                .attr("class", "yaxis axisHidden")
                .attr("transform", `translate( ${width + this.margin.left}, 0)`);
        }
        this.xaxis.transition().duration(1000).call(
            d3
                .axisBottom(x)
                //          .tickValues(tickValuesForAxis)
                .ticks(6)
            //            .tickSize(10)
            // .tickFormat((d, i) => {
            //   if (i % parseInt(this.data.length / 5) !== 0) return "";
            //   var r = "";
            //   var dt = parseTime(d);
            //   //if (i % parseInt(len/5) == 0 || i == len) r = formatTime(dt);
            //   return d;
            // })
        );
        this.yaxis.transition().duration(1000).call(d3.axisRight(y).ticks(4));
        this.init = false;
    }
}
