<template>
  <div class="layout layout-inner layout-support">
    <div class="content-holder w-100">
      <header class="content-header">
        <div class="container-1120 header-size">
          <h1>Support</h1>
        </div>
      </header>

      <section class="support">
        <ul class="support-list" support-list>
          <li
            v-for="support in supportlist"
            v-bind:key="support.id"
            :class="{ open: activeItem == support.url }"
          >
            <div class="container-1120">
              <div class="holder">
                <strong class="heading">
                  <router-link
                    :to="{
                      name: 'SupportSingle',
                      params: { slug: support.url },
                    }"
                    >{{ support.title }}</router-link
                  >
                </strong>

                <div class="expand" v-if="activeItem == support.url">
                  <div class="loader" v-if="loadingArticle">
                    <Loading />Momentje...
                  </div>
                  <div v-else>
                    <h1 class="title">{{ currentArticle.title }}</h1>
                    <div class="content-columns" v-html="currentArticle.text" />
                  </div>
                </div>
              </div>
            </div>
            <router-link :to="{ name: 'Support' }" class="close">l</router-link>
          </li>
        </ul>
      </section>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../components/Loading.vue";
export default {
  data: function () {
    return {
      supportlist: [],
      loadingArticle: true,
      currentArticle: {},
    };
  },
  components: {
    Loading,
  },
  computed: {
    activeItem() {
      return this.$route.params.slug;
    },
  },
  methods: {
    loadArticle: function (url) {
      this.loadingArticle = true;
      let that = this;
      axios
        .get("https://notfound.nl/api/support/" + url + "/")
        .then((response) => {
          that.currentArticle = response.data;
          that.loadingArticle = false;
        });
    },
  },
  watch: {
    $route(to) {
      //}, _fromIgnored) {
      if (to.params.slug !== undefined) this.loadArticle(to.params.slug);
    },
  },

  mounted: function () {
    axios.get("https://notfound.nl/api/support/").then((response) => {
      this.supportlist = response.data;
      if (this.activeItem != undefined) {
        this.loadArticle(this.activeItem);
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.loader {
  text-align: center;
  color: #333;
}

.support-list {
  .expand {
    background-color: #fff;
    padding: 2rem;
    color: #333;

    h1 {
      padding-bottom: 1rem;
    }
  }
}
</style>
