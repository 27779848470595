<template>
  <div class="layout layout-inner">
    <div class="content-holder w-100">
      <header class="content-header">
        <div class="container-1120 header-size">
          <h1>Algemene voorwaarden</h1>
        </div>
      </header>

      <section class="promo-quote">
        <div class="container-1120">
          <blockquote>
            <q
              >Where the waters do agree, it is quite wonderful the relief they
              give.</q
            >
            <cite>- Jane Austen, Emma</cite>
          </blockquote>
        </div>
      </section>

      <section class="text-columns text-columns-with-button">
        <div class="container-1120">
          <p>Download onze Algemene Voorwaarden als PDF:</p>
          <a href="/data/Nederland-ICT-Voorwaarden-2014.pdf" class="btn-arr"
            ><span>Download</span></a
          >
        </div>
      </section>
    </div>
  </div>
</template>
<script>
export default {
  metaInfo: {
    title: "Algemene voorwaarden",
  },
};
</script>