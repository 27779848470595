import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../components/FeaturedHome.vue";
import Profile from "../components/Profile.vue";
import Project from "../components/Project.vue";
import Contact from "../components/Contact.vue";
import Hosting from "../components/Hosting.vue";
import Icons from "../components/Icons.vue";
import Support from "../components/Support.vue";
import KnowledgeBase from "../components/KnowledgeBase.vue";
import KnowledgeBaseSingle from "../components/KnowledgeBaseSingle.vue";
import AnimatedHome from "../components/AnimatedHome.vue";
import Bier from "../components/Bier.vue";
import NotFound from "../components/NotFound.vue";
import Voorwaarden from "../components/Voorwaarden.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/graphs",
    name: "Home",
    component: Home,
  },
  {
    path: "/",
    name: "BetaHome",
    component: AnimatedHome,
  },
  {
    path: "/over-ons",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/app/:slug",
    name: "Apps",
    component: Project,
  },
  {
    path: "/website/:slug",
    name: "Websites",
    component: Project,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/voorwaarden",
    name: "Voorwarden",
    component: Voorwaarden,
  },
  {
    path: "/support",
    name: "Support",
    component: Support,
  },
  {
    path: "/support/:slug",
    name: "SupportSingle",
    component: Support,
  },
  {
    path: "/kb",
    name: "KnowledgeBase",
    component: KnowledgeBase,
  },
  {
    path: "/icons",
    name: "Icons",
    component: Icons,
  },
  {
    path: "/kb/:slug",
    name: "KnowledgeBaseCategory",
    component: KnowledgeBase,
  },
  {
    path: "/kb/:cat/:url",
    name: "KnowledgeBaseSingle",
    component: KnowledgeBaseSingle,
  },
  {
    path: "/hosting",
    name: "Hosting",
    component: Hosting,
  },
  {
    path: "/bier",
    name: "Bier",
    component: Bier,
  },
  {
    path: "*",
    name: "NotFound",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
