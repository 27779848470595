<template>
  <div :class="{ 'menu-animated': menuOpen }">
    <div v-if="loading" class="loading-screen">
      <Loading />
    </div>
    <div v-else id="sitewrapper" class="d-flex flex-column">
      <router-view class="d-flex flex-fill" />
      <FooterInfo class="d-flex" />
    </div>

    <Search />

    <nav
      class="main-menu-holder"
      @mouseover="menuOpen = true"
      @mouseleave="menuOpen = false"
    >
      <ul class="main-nav">
        <li @mouseover="popUpMenu = 0">
          <router-link to="/" title="NotFound" class="menu-item-logo">
            <strong>NotFound</strong>
            <span class="not-found-ico">
              <span>a</span>
            </span>
          </router-link>
        </li>
        <li class="is-sub-menu" :class="{ open: workOpen }">
          <a
            href="#"
            @click.prevent="workOpen = !workOpen"
            class="menu-item-services"
            @mouseover="popUpMenu = 0"
          >
            Werk & diensten
            <span class="not-found-ico">
              <span>q</span>
            </span>
          </a>

          <ul>
            <li class="is-expand-menu">
              <a
                href="javascript:void(0)"
                @mouseover="popUpMenu = 1"
                class="menu-item-websites submenu-item"
              >
                Websites
                <span class="not-found-ico">
                  <span>p</span>
                </span>
              </a>

              <div class="expand-menu-holder" v-show="popUpMenu == 1">
                <ul class="expand-menu">
                  <li v-for="website in websites" v-bind:key="website.id">
                    <router-link
                      class="projectlink"
                      v-on:click.native="popUpMenu = 0"
                      :to="`/app/${website.id}/`"
                      >{{ website.title }}</router-link
                    >
                  </li>
                </ul>

                <a
                  href="javascript:void(0)"
                  @click.prevent="popUpMenu = 0"
                  class="
                    popup-close
                    not-found-ico not-found-ico-close
                    j-projects-expand-close
                  "
                >
                  <span>l</span>
                </a>
              </div>
            </li>
            <li class="is-expand-menu">
              <a
                href="javascript:void(0)"
                @mouseover="popUpMenu = 2"
                class="menu-item-apps submenu-item"
              >
                Apps
                <span class="not-found-ico">
                  <span>o</span>
                </span>
              </a>

              <div class="expand-menu-holder" v-show="popUpMenu == 2">
                <ul class="expand-menu">
                  <li v-for="app in apps" v-bind:key="app.id">
                    <router-link
                      class="projectlink"
                      v-on:click.native="popUpMenu = 0"
                      :to="`/app/${app.id}/`"
                      >{{ app.title }}</router-link
                    >
                  </li>
                </ul>

                <a
                  href="javascript:void(0)"
                  @click.prevent="popUpMenu = 0"
                  class="
                    popup-close
                    not-found-ico not-found-ico-close
                    j-projects-expand-close
                  "
                >
                  <span>l</span>
                </a>
              </div>
            </li>
            <!--<li @mouseover="popUpMenu = 0">
              <router-link to="/hosting" class="menu-item-hosting submenu-item">
                Hosting
                <span class="not-found-ico">
                  <span>s</span>
                </span>
              </router-link>
            </li>
            <li @mouseover="popUpMenu = 0">
              <router-link to="/support" class="menu-item-support submenu-item">
                Support
                <span class="not-found-ico">
                  <span>r</span>
                </span>
              </router-link>
            </li>-->
          </ul>
        </li>
        <li @mouseover="popUpMenu = 0">
          <router-link to="/over-ons/" class="menu-item-profile">
            Profiel
            <span class="not-found-ico">
              <span>n</span>
            </span>
          </router-link>
        </li>
        <li @mouseover="popUpMenu = 0" class="active">
          <router-link to="/contact/" class="menu-item-contacts">
            Contact
            <span class="not-found-ico">
              <span>m</span>
            </span>
          </router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import Search from "./components/Search.vue";
import Loading from "./components/Loading.vue";
import FooterInfo from "./components/FooterInfo.vue";
import axios from "axios";

export default {
  name: "App",

  metaInfo: {
    titleTemplate: "%s - NotFound Digital Creativity",
  },

  components: {
    Search,
    FooterInfo,
    Loading,
  },
  data: function () {
    return {
      menuOpen: false,
      workOpen: false,
      popUpMenu: 0,
    };
  },

  mounted: function () {
    let that = this;
    axios
      .get("https://notfound.nl/api/projects/")
      .then((response) => {
        // eslint-disable-next-line
        console.log("%cLekker code aan het lezen?", "color: white; line-height: 60px; font-size: 30px; background: #000; font-weight: bold")
        // eslint-disable-next-line
        console.log("Dat zien we graag! Misschien kunnen we wat voor elkaar betekenen.");
        // eslint-disable-next-line
        console.log("Mail rene" + "@" + "notfound.nl om kennis te maken.");
        this.$store.commit("SET_PROJECTS", response.data);
      })
      .catch(function (err) {
        console.log (err);
        that.$store.commit("SET_PROJECTS", []);
      });
  },
  computed: {
    loading: function () {
      return this.$store.state.loading;
    },
    apps: function () {
      return this.$store.state.projects.filter((item) => item.type == "app");
    },
    websites: function () {
      return this.$store.state.projects.filter(
        (item) => item.type == "website"
      );
    },
  },
};
</script>
<style lang="scss">
.main-nav > li:hover {
  background-color: #333;
}

.submenu-item:hover {
  background-color: rgba(0, 136, 255, 0.75);
  color: #fff;
}
.projectlink {
  a {
    color: #000;
  }
  &:hover {
    background-color: rgba(0, 136, 255, 0.5);
    a {
      color: #fff;
    }
  }
}

#sitewrapper {
  min-height: 100vh;
}
</style>