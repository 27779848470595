<template>
  <div class="layout layout-project">
    <div
      class="content-holder w-100"
      :style="`background-image: url(${project.background})`"
    >
      <header class="project-header">
        <div class="dropdown container-1120 project-container">
          <div
            class="holder j-projects-menu-open"
            @click.prevent="showPopup = true"
          >
            <div class="project-title">
              <div class="cell ico">
                <span class="not-found-ico">
                  <span>p</span>
                </span>
              </div>
              <div class="cell text">
                <h1>{{ project.title }}</h1>
              </div>
            </div>
            <a href="#" class="popup-open"></a>
          </div>
        </div>
      </header>

      <section class="project">
        <div class="container-1120 project-container">
          <div class="project-screens">
            <vue-easy-lightbox
              :moveDisabled="true"
              :visible="visible"
              :imgs="project.screens"
              :index="index"
              @hide="handleHide"
            >
              <template v-slot:toolbar="{ toolbarMethods }">
                <p @click="toolbarMethods.rotateRight">&gt;</p>
              </template>
            </vue-easy-lightbox>

            <div
              class="screen"
              v-for="(screen, index) in project.screens"
              v-bind:key="index"
            >
              <img @click="showSingle(index)" :src="screen" alt />
            </div>
          </div>
        </div>

        <div class="project-details">
          <div class="container-1120 project-container">
            <div class="project-details-holder">
              <div class="d-flex row">
                <div class="col-md-8 col-xs-12">
                  <strong>Project details</strong>
                  <br />
                  <div class="description" v-html="project.description"></div>
                </div>
                <div class="col-md-4 col-xs-12 col-info">
                  <dl class="project-info">
                    <dt>Client</dt>
                    <dd>{{ project.client }}</dd>
                    <dt>Development</dt>
                    <dd>{{ project.development }}</dd>
                    <dt>Production</dt>
                    <dd>{{ project.production }}</dd>
                    <dt>Design</dt>
                    <dd>{{ project.design }}</dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="projects-menu" v-show="showPopup">
        <a
          @click="showPopup = false"
          class="popup-close not-found-ico j-projects-menu-close"
        >
          <span>l</span>
        </a>
        <ul class="expand-menu">
          <li v-for="item in otherProjects" v-bind:key="item.id">
            <a
              v-on:click="gotoProject(item.type == 'website' ? 'Websites' : 'Apps', item.id )"
              >{{ item.title }}</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  metaInfo() {
    return { title: this.project.title };
  },
  data: function () {
    return {
      background: "",
      showPopup: false,
      visible: false,
      index: 0,
      imgs: [
        "http://via.placeholder.com/350x150",
        "http://via.placeholder.com/350x150",
      ],
    };
  },
  methods: {
    gotoProject ( type, projectId ) {
      this.showPopup = false
      this.$router.push( { name: type, params: { slug: projectId } } )
    },
    handleHide() {
      this.visible = false;
    },
    showSingle(index) {
      this.index = index;
      this.visible = true;
    },
  },
  computed: {
    slug() {
      return this.$route.params.slug;
    },
    otherProjects: function () {
      return this.$store.state.projects.filter(
        (item) => item.id != this.project.id
      );
    },
    projects: {
      get: function () {
        return this.$store.state.projects;
      },
      set: function (newValue) {
        this.$store.commit("SET_PROJECTS", newValue);
      },
    },
    project() {
      return this.projects.filter((item) => item.id == this.slug)[0];
    },
  },
  mounted: function () {
    axios.get("https://notfound.nl/api/projects/").then((response) => {
      this.projects = response.data;
    });
  },
};
</script>
<style lang="scss" scoped>
.layout-project {
  padding-bottom: 0;
}
.dropdown:hover .project-title,
.dropdown:hover .project-title::after {
  background-color: rgba(0, 136, 255, 1);
}

.expand-menu li:hover {
  background-color: rgba(0, 136, 255, 0.5);
}

.expand-menu {
  padding-left: calc(50vw - 455px);
}
@media screen and (max-width: 1299px) {
  .expand-menu {
    padding-left: 161px;
  }
}

@media screen and (max-width: 1199px) {
  .expand-menu {
    padding-left: 142px;
  }
}

@media screen and (max-width: 992px) {
  .expand-menu {
    padding-left: 113px;
  }
}

.j-projects-menu-close {
  color: white;
}

.j-projects-menu-close span {
  transition: transform 0.2s ease-in-out;
}
.j-projects-menu-close:hover span {
  color: white;
  transition: transform 0.2s ease-in-out;
  transform: rotate(90deg);
}
@media screen and (min-width: 768px) {
  .description {
    padding-right: 50px;
  }
}
</style>