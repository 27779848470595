import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import VueMeta from "vue-meta";

import Lightbox from 'vue-easy-lightbox'

Vue.use(Lightbox)

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true,
});

// import BootstrapVue from "bootstrap-vue";
// Vue.use(BootstrapVue);

import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(VueGoogleMaps, {
  installComponents: true,
  load: {
    key: "AIzaSyAnYcjdDPYtiA-NBqYJOUOxmhRTcUYBi5k",
    libraries: "places",
  },
});

import store from "./store";

import "./styles/main.scss";

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
