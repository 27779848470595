<template>
  <div class="layout layout-inner layout-support">
    <div class="content-holder w-100">
      <header class="content-header">
        <div class="container-1120 header-size">
          <h1>Knowledge Base</h1>
        </div>
      </header>
      <section class="check-domain-section">
        <div class="container-1120">
          <form @submit.prevent="searchKb" class="check-domain-form">
            <fieldset>
              <div class="input-group">
                <input
                  type="text"
                  v-model="searchTerm"
                  class="form-control"
                  placeholder="Voer een zoekwoord in"
                />
                <span class="input-group-btn">
                  <button class="btn" type="submit">Zoeken</button>
                </span>
              </div>
            </fieldset>
          </form>
        </div>
      </section>
      <section class="support">
        <div v-if="searching">
          <div v-if="searchResults" style="position: relative">
            <div @click="searching = false" class="nfbutton close">l</div>

            <ul class="support-list">
              <li v-for="result in searchResults" v-bind:key="result.url">
                <div class="container-1120">
                  <div class="holder">
                    <strong class="heading">
                      <router-link :to="result.url">{{
                        result.title
                      }}</router-link>
                    </strong>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <Loading v-else />
        </div>

        <ul v-else class="support-list" support-list>
          <li
            v-for="support in supportlist"
            v-bind:key="support.id"
            :class="{ open: activeItem == support.url }"
          >
            <div class="container-1120">
              <div class="holder">
                <strong class="heading">
                  <router-link
                    :to="{
                      name: 'KnowledgeBaseCategory',
                      params: { slug: support.url },
                    }"
                    >{{ support.title }}</router-link
                  >
                </strong>

                <div class="expand" v-if="activeItem == support.url">
                  <div class="loader" v-if="loadingArticle">
                    <Loading />Momentje...
                  </div>
                  <div class="text-columns" v-else>
                    <h1>{{ currentArticle.title }}</h1>
                    <ul>
                      <li
                        v-for="item in currentArticle.list"
                        v-bind:key="item.url"
                      >
                        <router-link
                          :to="{
                            name: 'KnowledgeBaseSingle',
                            params: { cat: support.url, url: item.url },
                          }"
                          >{{ item.title }}</router-link
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <router-link :to="{ name: 'KnowledgeBase' }" class="close"
              >l</router-link
            >
          </li>
        </ul>
      </section>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../components/Loading.vue";

export default {
  metaInfo() {
    return {
      title: "Knowledge Base",
    };
  },
  data: function () {
    return {
      searchResults: null,
      searching: false,
      searchTerm: "",
      supportlist: [],
      loadingArticle: true,
      currentArticle: {},
    };
  },
  components: {
    Loading,
  },
  computed: {
    activeItem() {
      return this.$route.params.slug;
    },
  },
  methods: {
    searchKb: function () {
      (this.searchResults = null), (this.searching = true);
      axios
        .get("https://notfound.nl/api/kb/search/" + this.searchTerm + "/")
        .then((response) => {
          this.searchResults = response.data.list;
        });
    },
    loadArticle: function (url) {
      this.loadingArticle = true;
      let that = this;
      axios.get("https://notfound.nl/api/kb/" + url + "/").then((response) => {
        that.currentArticle = response.data;
        that.loadingArticle = false;
      });
    },
  },
  watch: {
    $route(to) {
      //}, _fromIgnored) {
      if (to.params.slug !== undefined) this.loadArticle(to.params.slug);
    },
  },

  mounted: function () {
    axios.get("https://notfound.nl/api/kb/").then((response) => {
      this.supportlist = response.data;
      if (this.activeItem != undefined) {
        this.loadArticle(this.activeItem);
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.loader {
  text-align: center;
  color: #333;
}

.text-columns {
  padding: 0;
  color: #000;

  ul {
    li {
      a {
        color: #000;
      }
    }
  }
  h1 {
    font-size: 1.875rem;
    @media (max-width: 992px) {
      font-size: 1.5625rem;
    }
  }
}

.support-list {
  .expand {
    background-color: #fff;
    padding: 2rem;

    .content-columns {
      color: #333;

      h4 {
        font-size: 1.2rem;
        padding-bottom: 1rem;
      }
    }
  }
}
</style>
