<template>
  <div class="layout-fullscreen">
    <div class="w-100" id="animation">
      <h1>Aanvragen notfound.nl</h1>
      <div id="graphwrapper">
        <div id="graph">

          <div id="tooltip">
            <div id="tooltiptext"></div>
          </div>
        </div>
        <a id="compliance" href="https://internet.nl/site/notfound.nl/"
          ><img src="/static/assets/images/embed-badge-websitetest.svg"
        /></a>
      </div>
    </div>
    <svg width="0" height="0" version="1.1">
      <defs>
        <linearGradient id="Gradient11" x1="0" x2="0" y1="0" y2="1">
          <stop offset="0%" stop-color="#7aa8c1" />
          <stop offset="100%" stop-color="#b8d1df" />
        </linearGradient>
        <linearGradient id="Gradient12" x1="0" x2="0" y1="0" y2="1">
          <stop offset="0%" stop-color="#314652" />
          <stop offset="100%" stop-color="#23323b" />
        </linearGradient>
        <linearGradient id="Gradient13" x1="0" x2="0" y1="0" y2="1">
          <stop offset="0%" stop-color="#fddbad" />
          <stop offset="100%" stop-color="#fdd297" />
        </linearGradient>
      </defs>
    </svg>
    <div id="error" v-if="errorText"><p>{{ errorText }}</p></div>  

  </div>
  
</template>
<script>
import * as d3 from "d3";
import barChart from "./barchart.js";

export default {
  data() {
    return {
      errorText: null
    };
  },
  metaInfo: {
    title: "Home",
  },
  mounted: function () {
    this.createGraph();
  },
  methods: {
    perDay(data, variable) {
      return d3
        .rollups(
          data,
          (xs) => d3.sum(xs, (x) => x[variable]),
          (d) => d.DATE
        )
        .map(([k, v]) => ({ DATE: k, [variable]: v }));
    },
    createGraph() {
      let that = this;
      d3.json("https://notfound.nl/api/stats/").then(function (data) {
        var parseDate = d3.timeParse("%Y-%m-%d");
        data.forEach(function (d) {
          d.DATE = parseDate(d.DATE);
        });
        var inChart = new barChart(
          "graph",
          data
          //that.perDay(data, "VALUE")
        );
        inChart.draw("VALUE", 1);
      })
      .catch( function (err) {
       that.errorText = "De gegevens voor de grafiek konden niet worden geladen..."
        console.log( err);
      });
    },
  },
};
</script>
<style lang="scss">
#animation {
  background: linear-gradient(
    252deg,
    rgba(105, 144, 141, 1) 0%,
    rgba(1, 138, 240, 1) 35%,
    rgba(1, 138, 240, 1) 100%
  );
  //  background-image: url("/static/assets/images/home.jpg");
  background-position: center center;
  background-size: cover;
  overflow: hidden;
  position: relative;
  display: flex;

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    overflow: hidden;
    @media screen and (max-width: 800px) {
      display: none;
    }
  }
}

#tooltip {
  display: none;
}

#compliance {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  opacity: 0.4;
  transition: opacity 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  &:hover {
    opacity: 1;
  }
}

#graphwrapper {
  margin: 100px;
  margin-right: 0;
  width: 100%;
  overflow: hidden;
  display: flex;
}

#graph {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
}

#tooltip {
  width: 200px;
  height: 60px;
  border: 1px solid #bbb;
  position: absolute;
  border-radius: 6px;
  background: #fff;
  text-align: center;
}

#tooltip:before {
  content: "";
  position: absolute;
  top: -6px;
  left: 100px;
  height: 10px;
  width: 10px;
  background: #fff;
  transform: rotate(45deg);
  border-top: inherit;
  border-left: inherit;
  box-shadow: inherit;
}

#tooltiptext h6 {
  margin: 3px;
  text-align: center;
  font-weight: bold;
}

#tooltiptext td {
  border: none;
  padding: 0;
}

#tooltiptext td.value {
  text-align: right;
}
#animation {
  h1 {
    position: absolute;
    top: 4rem;
    left: 6rem;
    color: white;
  }
}

#error {
  position: absolute;
  display: block;
  background-color: rgba(255, 255, 255, .6);
  border-radius: 1rem;
  border: 1px solid white;

top: 30%;
left: 20%;
right: 20%;
p {
  text-align: center;
  margin: 0;
  padding: 1rem;
}
}

</style>