import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: true,
    projects: []
  },
  mutations: {
    SET_PROJECTS: (state, newValue) => {
      state.projects = newValue;
      state.loading = false;
    }
  },
  actions: {
    setProjects: ({ commit, state }, newValue) => {
      commit("SET_PROJECTS", newValue);
      return state.projects;
    }
  }
});
