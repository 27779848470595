var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"layout layout-inner layout-support"},[_c('div',{staticClass:"content-holder w-100"},[_vm._m(0),_c('section',{staticClass:"check-domain-section"},[_c('div',{staticClass:"container-1120"},[_c('form',{staticClass:"check-domain-form",on:{"submit":function($event){$event.preventDefault();return _vm.searchKb.apply(null, arguments)}}},[_c('fieldset',[_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchTerm),expression:"searchTerm"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Voer een zoekwoord in"},domProps:{"value":(_vm.searchTerm)},on:{"input":function($event){if($event.target.composing)return;_vm.searchTerm=$event.target.value}}}),_vm._m(1)])])])])]),_c('section',{staticClass:"support"},[(_vm.searching)?_c('div',[(_vm.searchResults)?_c('div',{staticStyle:{"position":"relative"}},[_c('div',{staticClass:"nfbutton close",on:{"click":function($event){_vm.searching = false}}},[_vm._v("l")]),_c('ul',{staticClass:"support-list"},_vm._l((_vm.searchResults),function(result){return _c('li',{key:result.url},[_c('div',{staticClass:"container-1120"},[_c('div',{staticClass:"holder"},[_c('strong',{staticClass:"heading"},[_c('router-link',{attrs:{"to":result.url}},[_vm._v(_vm._s(result.title))])],1)])])])}),0)]):_c('Loading')],1):_c('ul',{staticClass:"support-list",attrs:{"support-list":""}},_vm._l((_vm.supportlist),function(support){return _c('li',{key:support.id,class:{ open: _vm.activeItem == support.url }},[_c('div',{staticClass:"container-1120"},[_c('div',{staticClass:"holder"},[_c('strong',{staticClass:"heading"},[_c('router-link',{attrs:{"to":{
                    name: 'KnowledgeBaseCategory',
                    params: { slug: support.url },
                  }}},[_vm._v(_vm._s(support.title))])],1),(_vm.activeItem == support.url)?_c('div',{staticClass:"expand"},[(_vm.loadingArticle)?_c('div',{staticClass:"loader"},[_c('Loading'),_vm._v("Momentje... ")],1):_c('div',{staticClass:"text-columns"},[_c('h1',[_vm._v(_vm._s(_vm.currentArticle.title))]),_c('ul',_vm._l((_vm.currentArticle.list),function(item){return _c('li',{key:item.url},[_c('router-link',{attrs:{"to":{
                          name: 'KnowledgeBaseSingle',
                          params: { cat: support.url, url: item.url },
                        }}},[_vm._v(_vm._s(item.title))])],1)}),0)])]):_vm._e()])]),_c('router-link',{staticClass:"close",attrs:{"to":{ name: 'KnowledgeBase' }}},[_vm._v("l")])],1)}),0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"content-header"},[_c('div',{staticClass:"container-1120 header-size"},[_c('h1',[_vm._v("Knowledge Base")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"input-group-btn"},[_c('button',{staticClass:"btn",attrs:{"type":"submit"}},[_vm._v("Zoeken")])])
}]

export { render, staticRenderFns }