<template>
  <div class="layout layout-inner layout-support">
    <div class="content-holder w-100">
      <header class="content-header">
        <div class="container-1120 header-size">
          <h1>Iconen veiligheidsregio's</h1>
        </div>
      </header>
      <section class="check-domain-section">
        <div class="container-1120">
          <form @submit.prevent="searchIcons" class="check-domain-form">
            <fieldset>
              <div class="input-group">
                <input
                  type="text"
                  v-model="searchTerm"
                  class="form-control"
                  placeholder="Voer een keyword in"
                />
                <span class="input-group-btn">
                  <button class="btn" type="submit">Zoeken</button>
                </span>
              </div>
            </fieldset>
          </form>
        </div>
      </section>
      <section class="support">
        <div class="container-1120">
          <Loading v-if="loading" />

          <div class="row" v-else>
            <div
              v-for="icon in iconfiltered"
              v-bind:key="icon.id"
              class="col-xs-12 col-md-4 col-lg-3"
            >
              <div
                @click="currentIcon = icon"
                class="
                  svgimage
                  d-flex
                  flex-column
                  justify-content-center
                  align-items-center
                "
              >
                <img :src="icon.image" />
                <p class="d-flex">{{ icon.name }}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="overlay" v-if="currentIcon != null"></div>
    <div
      id="iconpopup"
      class="d-flex flex-column justify-content-center align-items-center"
      v-if="currentIcon != null"
    >
      <h1>{{ currentIcon.name }}</h1>
      <p>
        Deze iconen zijn alleen vrij te gebruiken voor sites gebouwd door
        NotFound.
      </p>
      <img class="iconpreview" :src="currentIcon.image" />
      <a target="_blank" :href="currentIcon.image" class="btn btn-primary"
        >Download als SVG</a
      >
      <p @click="currentIcon = null" class="btn btn-secundairy">
        Sluit deze popup
      </p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../components/Loading.vue";

export default {
  metaInfo() {
    return {
      title: "Iconen",
    };
  },
  data: function () {
    return {
      currentIcon: null,
      searchTerm: "",
      iconlist: [],
      loading: true,
    };
  },
  components: {
    Loading,
  },
  computed: {
    iconfiltered() {
      if (this.searchTerm.trim() == "") return this.iconlist;
      else
        return this.iconlist.filter(
          (item) =>
            item.name.toLowerCase().indexOf(this.searchTerm.toLowerCase()) >
              -1 ||
            item.keywords.toLowerCase().indexOf(this.searchTerm.toLowerCase()) >
              -1
        );
    },
  },
  mounted: function () {
    axios.get("https://notfound.nl/api/icon/").then((response) => {
      this.iconlist = response.data;
      this.loading = false;
    });
  },
};
</script>

<style lang="scss" scoped>
.loader {
  text-align: center;
  color: #333;
}

.text-columns {
  padding: 0;
  color: #000;

  ul {
    li {
      a {
        color: #000;
      }
    }
  }
}

.support-list {
  .expand {
    background-color: #fff;
    padding: 2rem;

    .content-columns {
      color: #333;

      h4 {
        font-size: 1.2rem;
        padding-bottom: 1rem;
      }
    }
  }
}

.svgimage {
  background-color: #08f;
  border-radius: 0.5rem;
  height: 200px;

  img {
    height: 150px;
  }

  p {
    color: #fff;
    text-shadow: 0px 0px 6px #333;
  }
}

#iconpopup {
  position: fixed;
  width: 300px;
  margin-left: calc(50% - 150px);
  margin-top: 100px;
  z-index: 9000;
  padding: 2rem;
  background-color: white;
  border: 4px solid #08f;
  border-radius: 1rem;

  h1 {
    font-size: 19px;
    margin-bottom: 1rem;
  }

  .iconpreview {
    background-color: #08f;
    padding: 1rem;
    border-radius: 1rem;
    display: block;
    height: 100px;
    margin-bottom: 1rem;
  }
}

.overlay {
  display: flex;
  top: 0px;
  position: fixed;
  height: 100vh;
  width: 100%;
  text-align: center;
  color: #08f;
  font-size: 2rem;
  z-index: 8000;
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 2rem;
}
</style>
