<template>
  <div class="layout layout-inner layout-support">
    <div class="content-holder w-100">
      <header class="content-header">
        <div class="container-1120 header-size">
          <h1>{{ currentArticle.title }}</h1>
        </div>
      </header>

      <section class="text-columns">
        <loading v-if="loadingArticle" />
        <div
          class="container-1120 support-padding"
          v-html="currentArticle.text"
        />
      </section>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../components/Loading.vue";

export default {
  metaInfo() {
    return {
      title: this.currentArticle.title,
    };
  },
  data: function () {
    return {
      loadingArticle: true,
      currentArticle: {},
    };
  },
  components: {
    Loading,
  },
  computed: {
    activeItem() {
      return this.$route.params.url;
    },
  },
  methods: {
    loadArticle: function (url) {
      this.loadingArticle = true;
      let that = this;
      axios
        .get("https://notfound.nl/api/kb/article/" + url + "/")
        .then((response) => {
          that.currentArticle = response.data;
          that.loadingArticle = false;
        });
    },
  },
  watch: {
    $route(to) {
      ///}, _fromIgnored) {
      if (to.params.url !== undefined) this.loadArticle(to.params.url);
    },
  },

  mounted: function () {
    this.loadArticle(this.$route.params.url);
  },
};
</script>

<style lang="scss" scoped>
.loader {
  text-align: center;
  color: #333;
}

.support-list {
  .expand {
    background-color: #fff;
    padding: 2rem;

    .content-columns {
      color: #333;

      h4 {
        font-size: 1.2rem;
        padding-bottom: 1rem;
      }
    }
  }
}
</style>
