<template>
  <div class="d-flex flex-column">
    <GmapMap
      class="d-flex flex-fill"
      :center="{lat:52.089620, lng:5.119070}"
      :zoom="15"
      style="width: 100%; height: 100%"
      :options="styles"
    >
      <GmapMarker
        title="Visschersplein 1, Utrecht"
        icon="/static/assets/images/marker.png"
        :position="{lat:52.089620, lng:5.119070}"
      />
    </GmapMap>

    <div class="contacts-row d-flex flex-1">
      <div class="container-fluid">Visschersplein 1, Utrecht, 088-NOTFOUND</div>
    </div>
  </div>
</template>
<script>
export default {
  metaInfo: {
    title: "Contact"
  },
  data: function() {
    return {
      styles: {
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        disableDefaultUi: false,
        styles: [
          { stylers: [{ hue: "#0088FF" }, { gamma: 0.9 }] },
          {
            featureType: "poi",
            elementType: "labels",
            stylers: [{ visibility: "off" }]
          },
          { featureType: "water", stylers: [{ color: "#0088FF" }] }
        ]
      }
    };
  },

  components: {}
};
</script>

<style lang="scss" scoped>
</style>