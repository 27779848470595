<template>
  <div class="layout-fullscreen">
    <div class="w-100" id="animation"></div>
  </div>
</template>
<script>
/* eslint-disable */
import * as THREE from "three";
const TWEEN = require("@tweenjs/tween.js");
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
/* eslint-enable */

export default {
  metaInfo() {
    return { title: 'Welcome' };
  },
  data() {
    return {
      camera: null,
      scene: null,
      renderer: null,
      mesh: null,
      logo: null,
      controls: null,
      elements: [],
      rotationspeeds: [],
      resetPosition: null,
      messy: false,
      running: true,
      stars: [],
      radius: 10,
      numberOfStars: 40,
    };
  },
  methods: {
    init: function () {
      let container = document.getElementById("animation");

      this.camera = new THREE.PerspectiveCamera(
        70,
        container.clientWidth / container.clientHeight,
        0.01,
        10
      );
      this.camera.position.z = -4;

      this.scene = new THREE.Scene();

      this.scene.add(new THREE.AmbientLight(0xffffff, 0.3));


      var geometry   = new THREE.SphereGeometry(0.007, 32, 32)
           var material = new THREE.MeshBasicMaterial( {color: 0xffffff} );
         // The loop will move from z position of -1000 to z position 1000, adding a random particle at each position. 
         for ( var z= -this.radius; z < this.radius; z+=this.radius/this.numberOfStars ) {
     
           // Make a sphere (exactly the same as before). 
  
           var sphere = new THREE.Mesh(geometry, material)
     
           // This time we give the sphere random x and y positions between -500 and 500
           sphere.position.x = Math.random() * this.radius - this.radius/2;
           sphere.position.y = Math.random() * this.radius - this.radius/2;
     
           // Then set the z position to where it is in the loop (distance of camera)
           sphere.position.z = z;
     
           // scale it up a bit
           sphere.scale.x = sphere.scale.y = 2;
     
           //add the sphere to the scene
           this.scene.add( sphere );
     
           //finally push it to the stars array 
           this.stars.push(sphere); 
         }
   

      //   var light = new THREE.HemisphereLight(0xbbbbff, 0x444422);
      var dirLight = new THREE.SpotLight (0xffffff, 6, 5);
      dirLight.position.set(1.7, 2.3, -1.2);
      dirLight.lookAt( 0 ,0 ,0)
     dirLight.castShadow = true

//Set up shadow properties for the light
dirLight.shadow.mapSize.width = 1024; // default
dirLight.shadow.mapSize.height = 2048; // default
//dirLight.shadow.normalBias = 0.003
dirLight.shadow.bias = - 0.010
let someValue = .1;
dirLight.shadow.camera.left = someValue;
dirLight.shadow.camera.right = someValue;
dirLight.shadow.camera.top = someValue;
dirLight.shadow.camera.bottom = someValue;

dirLight.shadow.camera.far = 2;
dirLight.shadow.camera.near = 1;



       this.scene.add(dirLight);
      //  this.scene.add(light);
      var loader = new GLTFLoader();

      this.resetPosition = { ...this.camera.position };


//       let boxMaterial = new THREE.MeshBasicMaterial();
// let box = new THREE.BoxBufferGeometry( .3,.4,.4);


// const boxMesh = new THREE.Mesh( box, boxMaterial );

// boxMesh.receiveShadow = true

// this.scene.add( boxMesh);

      var that = this;
      loader.load(
        "/static/3d/logo.glb",
        function (model) {
          that.logo = model.scene;
          let i =0;
          that.logo.traverse(function (child) {
            i++;
            if (child instanceof THREE.Mesh) {
              that.elements.push(child);
              that.rotationspeeds.push([
                Math.random() / 100,
                Math.random() / 100,
                Math.random() / 100,
              ]);
             if (child.isMesh && i >0  ) child.castShadow = true;
                    if (child.isMesh || child.isLight) child.receiveShadow = true;
            }
          });
          that.logo.scale.set(0.2, 0.2, 0.2);
          that.logo.rotation.y = Math.PI;
          that.logo.rotation.x += 2;

          that.scene.add(that.logo);

        },
        // function (xhr) {
        //  // console.log(xhr.loaded);
        // }
      );

      this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);

      this.renderer.shadowMap.enabled = true;
      this.renderer.shadowMap.type = THREE.PCFSoftShadowMap; 
      this.controls.enableDamping = true;

      this.controls.maxDistance = 7;
      this.controls.minDistance = 1;

      this.renderer;

      this.renderer.setSize(container.clientWidth, container.clientHeight);
      container.appendChild(this.renderer.domElement);
      this.renderer.domElement.addEventListener(
        "dblclick",
        () => {
          this.messy = !this.messy;
          this.controls.enabled = this.messy;
          //   window.console.log(this.messy);
          console.log(this.camera);
          if (!this.messy) {
            new TWEEN.Tween(this.camera.position)
              .to(this.resetPosition, 3600)
              .easing(TWEEN.Easing.Quadratic.Out)
              .start();
          }
        },
        true
      );
    },
    animate: function () {
      TWEEN.update();
      this.controls.update();
      var i = 0;
       for(i=0; i< this.stars.length; i++) {
       
        // and move it forward dependent on the mouseY position. 
        this.stars[i].position.z -=  i/700;

        // if the particle is too close move it to the back
        if(this.stars[i].position.z<-this.radius) this.stars[i].position.z =  this.radius ; 
       
      }

      if (this.running) requestAnimationFrame(this.animate);
      if (this.logo !== null) {
        this.logo.rotation.z = Math.sin((new Date()).getTime()/3000)/8;
        this.logo.rotation.x = 1.8+  Math.cos((new Date()).getTime()/2000)/8;
        this.logo.rotation.y =  Math.cos((new Date()).getTime()/34000)/8;

        if (this.messy) {
          this.logo.rotation.z += 0.004;
        } else {
          this.logo.rotation.z -= this.logo.rotation.z / 50;
        }
        for ( i = 0; i < this.elements.length; i++) {
          if (this.elements[i].rotation.x > Math.PI * 2) this.elements[i].rotation.x = 0;
          if (this.elements[i].rotation.y > Math.PI * 2) this.elements[i].rotation.y = 0;
          if (this.elements[i].rotation.z > Math.PI * 2) this.elements[i].rotation.z = 0;
          if (this.messy) {
            this.elements[i].rotation.x += this.rotationspeeds[i][0];
            this.elements[i].rotation.y += this.rotationspeeds[i][1];
            this.elements[i].rotation.z += this.rotationspeeds[i][2];
          } else {
            this.elements[i].rotation.x -= this.elements[i].rotation.x / 50;
            this.elements[i].rotation.y -= this.elements[i].rotation.y / 50;
            this.elements[i].rotation.z -= this.elements[i].rotation.z / 50;
          }
        }

        this.controls.update();
      }

      this.renderer.render(this.scene, this.camera);
    },
  },
  mounted() {
    this.init();
    this.animate();
  },
  beforeDestroy() {
    this.running = false;
  },
};
</script>
<style lang="scss" scoped></style>
