import { render, staticRenderFns } from "./AnimatedHome.vue?vue&type=template&id=2b900474&scoped=true"
import script from "./AnimatedHome.vue?vue&type=script&lang=js"
export * from "./AnimatedHome.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b900474",
  null
  
)

export default component.exports