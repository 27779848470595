<template>
<div>
    <form action="#" class="search-form" :class="{'open': searchOpen}">
        <fieldset>
            <input ref="searchInput" type="text" placeholder="Typ om te zoeken" v-model="search" />
            <a href="#" @click.prevent="toggleSearch" class="search-toggle"></a>
        </fieldset>
    </form>

    <ul v-if="searchOpen && search!=''" class="ui-autocomplete ui-front ui-menu ui-widget ui-widget-content" id="ui-id-1" tabindex="0">
        <li v-for="project in projectSearch" v-bind:key="project.id">
            <router-link class="projectlink" tabindex="-1" v-on:click.native="toggleSearch()" :to="{ name: 'Websites', params: { slug: project.id }}">
                <span class="ui-menu-item">
                    <span class="not-found-ico">
                        <span v-if="project.type=='app'">o</span>
                        <span v-else>p</span>
                    </span>
                    {{ project.title}}
                </span>
            </router-link>
        </li>
    </ul>

    <div v-if="searchOpen && projectSearch.length == 0" class="noresults">
        <em>Geen resultaten</em>
    </div>
</div>
</template>

<script>
export default {
    data: function () {
        return {
            searchOpen: false,
            search: "",
        };
    },
    methods: {
        toggleSearch() {
            if (this.searchOpen) {
                this.search = "";
                this.searchOpen = false;
            } else {
                this.searchOpen = true;
                this.$refs.searchInput.focus();
            }
        },
    },
    computed: {
        projectSearch() {
            return this.$store.state.projects.filter(
                (item) =>
                item.title.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
                item.description.toLowerCase().indexOf(this.search.toLowerCase()) >
                -1 ||
                item.client.toLowerCase().indexOf(this.search.toLowerCase()) > -1
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.ui-autocomplete li {
    padding: 0;

    a {
        padding: 18px 18px 18px 0;
        display: block;
        color: #333;
    }
}

.search-form {
    z-index: 50;
}

.noresults {
    display: flex;
    top: 60px;
    position: absolute;
    height: 100vh;
    width: 100%;
    text-align: center;
    color: #08f;
    font-size: 2rem;
    z-index: 9001;
    align-items: center;
    font-size: 2rem;

    em {
        width: 100%;
        animation: glow 3s ease-in-out infinite alternate;
    }
}

@keyframes glow {
    from {
        color: #08f;
        transform: scale(1.1);
    }

    to {
        color: #000;
    }
}
</style>
