<template>
  <div class="layout layout-inner">
    <div class="content-holder w-100">
      <header class="content-header">
        <div class="container-1120 header-size">
          <h1>Profiel</h1>
        </div>
      </header>

      <section class="promo-quote">
        <div class="container-1120">
          <blockquote>
            <q>The best way to complain is to make things.</q>
            <cite>James Murphy</cite>
          </blockquote>
        </div>
      </section>

      <section class="text-columns text-columns-with-button">
        <div class="container-1120">
          <strong>Wij maken websites en apps</strong>
          <div class="row">
            <div class="col-md-11">
              <p>
                We werken veel voor diverse bedrijven, overheidsorganisaties en
                non-profits.
              </p>
              <p>
                We hebben een eigen CMS ontwikkeld, waarmee we
                maatwerkoplossingen bieden.
              </p>
            </div>
            <div class="col-md-11 col-md-offset-2">
              <p>
                We beschikken over een uitgebreid netwerk van web en
                designprofessionals en voor ieder project stellen we het beste
                team samen.
              </p>
              <p>
                We leveren de technische mensen en projectleiders die samen met
                ontwerpers en webspecialisten aan de slag gaan. We hebben een
                ‘can do’ mentaliteit en leveren praktische oplossingen voor
                uitdagende webprojecten.
              </p>
              <p>
                Benieuwd naar wat we voor jou kunnen doen? We maken graag
                kennis!
              </p>
            </div>
          </div>
          <div class="row">
            <!-- <div class="col-xs-12 col-xs-offset-13"> -->
            <div class="col-6">
              <a href="mailto:info@notfound.nl" class="btn-arr">
                <span>Say hi right away</span>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section class="content-visual">
        <div class="visual">
          <img src="/static/assets/images/sluis.jpg" alt="Weerdsluis" />
        </div>
        <div class="title">
          <div class="container-1120">Weerdsluis</div>
        </div>
      </section>

      <!-- <section class="text-columns">
        <div class="container-1120">
          <strong></strong>
          <div class="row">
            <div class="col-md-11">
              Binnenkort meer!
            </div>
          </div>
        </div>
      </section> -->
    </div>
  </div>
</template>
<script>
export default {
  metaInfo: {
    title: "Over ons",
  },
};
</script>