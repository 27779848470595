<template>
  <div class="layout layout-inner">
    <div class="content-holder w-100">
      <header class="content-header">
        <div class="container-1120 header-size">
          <h1>Vier nul bier</h1>
        </div>
      </header>

      <section class="promo-quote">
        <div class="container-1120">
          <blockquote>
            <q>Een heerlijk pilsner</q>
            <cite>- Iedere bierkenner</cite>
          </blockquote>
        </div>
      </section>

      <section class="text-columns text-columns-with-button">
        <div class="container-1120">
          <p>
            Wij maken niet alleen websites en apps, maar ook ons eigen bier.
          </p>
          <p>We horen graag of het gesmaakt heeft!</p>
          <a href="mailto:info@notfound.nl" class="btn-arr">
            <span>Proost</span>
          </a>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
export default {
  metaInfo: {
    title: "BIER",
  },
};
</script>